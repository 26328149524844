import React, { useState } from "react";
import ButtonLoader from "./button-loader";

const AsyncButton = ({ variant = undefined, disabled, children, onClick, className }) => {
    const [isLoading, setisLoading] = useState(false);

    const derivedOnClick = () => {
        setisLoading(true);

        setTimeout(() => {
            setisLoading(false);
            onClick();
        }, 220);
    };
    return (
        <button disabled={isLoading || disabled} className={className} onClick={derivedOnClick}>
            {isLoading ? <ButtonLoader variant={variant || "secondary"} size="small" /> : children}
        </button>
    );
};

export default AsyncButton;
