import React from "react";
import "./button-loader.css"; // Importamos los estilos

// Componente Loader
const ButtonLoader = ({ size = "default", className = "", variant = "primary", ...props }) => {
    const sizeClasses = {
        small: "loader-small",
        default: "",
        medium: "loader-medium",
        large: "loader-large"
    };

    const variantClasses = {
        primary: "",
        secondary: "loader-secondary",
        ghost: "loader-ghost"
    };

    const loaderClassName = ["button-loader", sizeClasses[size] || "", variantClasses[variant] || "", className]
        .filter(Boolean)
        .join(" ");

    return <div className={loaderClassName} {...props} />;
};

export default ButtonLoader;
