import React from "react";
import tgLogo from "../images/telegram-logo.png";
import wppLogo from "../images/whatsapp-logo.svg";
import * as firebase from "firebase";
import ReactPixel from "react-facebook-pixel";
import AsyncButton from "./AsyncButton";

const Popup = ({ isOpen, onClose, professional }) => {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay desktop">
            <div className="popup-container">
                <div className="popup-header">
                    <h3>Consulta médica</h3>
                    <button className="popup-close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="popup-content">
                    {professional.openButtons.map((buttonConf, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: 4
                                }}
                            >
                                <div className="init-consultation-tg">
                                    <AsyncButton
                                        disabled={buttonConf.disabled}
                                        className="button"
                                        onClick={() => {
                                            firebase.analytics().logEvent("webcard_open_app_clicked");
                                            ReactPixel.trackCustom("webcard_open_app_clicked");
                                            window.open(buttonConf.openUrl, "_self");
                                            onClose();
                                        }}
                                    >
                                        {buttonConf.name}
                                    </AsyncButton>
                                    <span>en</span>
                                    <img
                                        src={buttonConf.type === 1 ? tgLogo : wppLogo}
                                        alt=""
                                        style={{
                                            width: 90
                                        }}
                                    />
                                </div>
                                <p style={{ fontSize: 13, margin: 0 }}> {buttonConf.description}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="popup-footer"></div>
            </div>
        </div>
    );
};

export default Popup;
