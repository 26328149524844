import React, { PureComponent } from "react";
import axios from "axios";
import profile from "./images/default-profile.png";

import ls from "local-storage";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import * as firebase from "firebase";
import ReadMoreAndLess from "react-read-more-less";
import { firebaseConfig } from "./config/firebase";

import "./App.css";
import Popup from "./components/popup";
import Loader from "./components/loader";
import ButtonLoader from "./components/button-loader";
import { closeWebCardApp, getRedirectId } from "./utils/getRedirectUrl";
import AsyncButton from "./components/AsyncButton";

let userOrigin;

const medicText =
    "DoqChat te conecta con tu médico en un marco seguro y específico para realizar consultas que no requieren un contacto presencial.";

function getTitle(title) {
    switch (title) {
        case 1:
            return "Dr.";
        case 2:
            return "Dra.";
        case 3:
            return "Lic.";
        default:
            return "Profesional";
    }
}

class App extends PureComponent {
    constructor(props) {
        super(props);

        firebase.initializeApp(firebaseConfig);
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
        LinkedInTag.init(process.env.REACT_APP_LINKEDIN_TAG_ID);

        ReactPixel.track("PageView");
        firebase.analytics();

        this.state = {
            route: "home",
            loading: true,
            redirectExpired: false,
            error: null,
            professional: null,
            profileImageLoaded: false,
            isPopupOpen: false,
            downloadLoading: false,
            redirectLoading: false
        };

        const urlParams = new URLSearchParams(window.location.search);
        userOrigin = urlParams.get("origin") || urlParams.get("o") || null;
    }

    openPopup = () => {
        this.setState({ isPopupOpen: true });
    };

    closeWebcard = () => {
        // window.close();
        closeWebCardApp();
    };

    closePopup = () => {
        this.setState({ isPopupOpen: false });
    };

    setDownloadLoading = (value) => {
        this.setState({ downloadLoading: value });
    };

    componentDidMount() {
        // Get url path
        const path = window.location.pathname;
        const redirectId = getRedirectId(window.location.href);

        if (redirectId) {
            // Redirect handle
            this.completeRedirectAction(redirectId);
        } else if (path && !redirectId) {
            // Find medic by url code
            const splittedPath = path.split("/");
            const medicCode = splittedPath[1];

            if (medicCode) {
                this.setState({
                    username: medicCode
                });

                axios
                    .get(`${process.env.REACT_APP_BASE_API_URL}/v1/medic/bycode/${medicCode}`, {
                        params: {
                            webcard: true,
                            origin: userOrigin
                        }
                    })

                    .then((response) => {
                        // Example response:
                        // {
                        // 	"title": 1,
                        // 	"id": "b74fa42de5ab48688b9d1ecd7237917e",
                        // 	"firstName": "Prueba",
                        // 	"lastName": "Mensajes",
                        // 	"code": "pruebamensajes1",
                        // 	"deleted": false
                        // }
                        if (response.data.isFaulted) {
                            return Promise.reject(new Error(response.data.errors[0].message));
                        }

                        this.setState({
                            professional: response.data
                        });

                        if (response.data.saveContactMainFlow && response.data.autoSaveContact) {
                            // Guardar automáticamente el contacto

                            const medicId = response.data.id;

                            setTimeout(() => {
                                this.getVCFCard(medicId);
                            }, response.data.autoSaveContactDelay);
                        }

                        if (!ls.get(`page-viewed-${medicCode}`)) {
                            // Register webcard view count
                            // Comment by @oviedo97fer | 22/10/2024
                            // TODO: recheck this metric
                            /*  axios.post(`${process.env.REACT_APP_BASE_API_URL}/medicos/aperturawebcard`, {
                            codigo: splittedPath[1]
							}).then(() => {
								ls.set(`page-viewed-${splittedPath[1]}`, 'send')
							}) */
                        }
                    })
                    .catch((error) => {
                        console.log({ error });
                        this.setState({
                            error: error ? error.message : ""
                        });
                    })

                    .finally(() => {
                        this.setState({
                            loading: false
                        });
                    });
            } else {
                // GET api/v1/medic/webcarddata

                axios
                    .get(`${process.env.REACT_APP_BASE_API_URL}/v1/medic/webcarddata`, {
                        params: {
                            webcard: true,
                            origin: userOrigin
                        }
                    })
                    .then((response) => {
                        this.setState({
                            professional: response.data
                        });
                    });
                this.setState({
                    loading: false,
                    root: true
                });
            }
        } else {
            this.setState({
                loading: false,
                root: true
            });
        }
    }

    completeRedirectAction = (redirectId) => {
        this.setState({
            redirectLoading: true
        });
        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/GetActionUrl/${redirectId}`, {
                params: {}
            })
            .then((response) => {
                if (!response.data) return;
                const expired = response.data.expired;
                const actionRedirecUrl = response.data.actionUrl;
                if (expired || !actionRedirecUrl) {
                    this.setState({
                        loading: false,
                        redirectExpired: true
                    });
                }

                if (actionRedirecUrl) {
                    window.location.replace(actionRedirecUrl);
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    redirectExpired: true
                });
            })
            .finally(() => {
                // this.setDownloadLoading(false);
            });
    };

    getVCFCard = (medicId) => {
        this.setDownloadLoading(true);
        axios
            .get(`${process.env.REACT_APP_BASE_API_URL}/v1/medic/vcard/${medicId}`, {
                params: {
                    origin: userOrigin
                }
            })
            .then((response) => new Blob([response.data.vCard], { type: "text/vcard" }))
            .then((blob) => {
                console.log({ blob });
                const url = window.URL.createObjectURL(blob);

                // Crear un elemento <a> para descargar
                const downloadLink = document.createElement("a");
                downloadLink.href = url;
                downloadLink.download = `contacto_${medicId}.vcf`;

                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Limpieza
                setTimeout(() => {
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(url);
                }, 100);
            })
            .finally(() => {
                this.setDownloadLoading(false);
            });
    };

    handleCTA = () => {
        if (this.state.professional.saveContactMainFlow) {
            this.getVCFCard(this.state.professional.id);
        } else {
            this.openPopup();
        }
    };

    render() {
        if (this.state.root) {
            return (
                <div className="body">
                    <div className="page">
                        <section className="section">
                            <div className="about-me-container">
                                <div className="about-me-top">
                                    <div className="about-me-title">Bienvenido</div>
                                    <div className="code-line"></div>
                                </div>
                            </div>

                            <div className="mobile">
                                <div className="bottom-legend">{medicText}</div>

                                <div className="doqchat-logo-container">
                                    <img
                                        src="https://doqchat.com/static/doqchat-logo.png"
                                        className="doqchat-logo"
                                        alt="doq-chat logo"
                                    ></img>
                                </div>

                                {!!this.state.professional && (
                                    <>
                                        <div
                                            className="bottom-container"
                                            style={{ display: "flex", flexDirection: "column", gap: 8 }}
                                        >
                                            <AsyncButton className="button" onClick={this.openPopup}>
                                                Iniciar Consulta
                                            </AsyncButton>

                                            {/* Guardar contacto: loading con vcf */}
                                        </div>
                                    </>
                                )}
                            </div>
                        </section>
                    </div>
                    <Popup
                        isOpen={this.state.isPopupOpen}
                        onClose={this.closePopup}
                        professional={this.state.professional}
                    />
                </div>
            );
        }

        if (this.state.redirectExpired) {
            return (
                <div className="body">
                    <div className="page">
                        <section className="section">
                            <div className="about-me-container">
                                <div className="about-me-top">
                                    <div className="about-me-title">Link Expirado</div>
                                    <div className="code-line"></div>
                                </div>
                            </div>

                            <div className="mobile">
                                <div className="bottom-legend">
                                    Este link se encuentra caducado, por favor, vuelva a la app para continuar
                                </div>

                                <div
                                    className="bottom-container"
                                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                                >
                                    <AsyncButton className="button" onClick={this.closeWebcard}>
                                        Volver a la app
                                    </AsyncButton>
                                </div>

                                <div className="doqchat-logo-container">
                                    <img
                                        src="https://doqchat.com/static/doqchat-logo.png"
                                        className="doqchat-logo"
                                        alt="doq-chat logo"
                                    ></img>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Popup
                        isOpen={this.state.isPopupOpen}
                        onClose={this.closePopup}
                        professional={this.state.professional}
                    />
                </div>
            );
        }

        if (this.state.error) {
            return (
                <div className="body">
                    <div className="page">
                        <section className="section">
                            <div className="about-me-container">
                                <div className="about-me-top">
                                    <div className="about-me-title">Encontramos un problema</div>
                                    <div className="code-line"></div>
                                </div>
                                <div className="bio-text">
                                    {this.state.error.includes("404")
                                        ? "El doctor que buscas no existe"
                                        : this.state.error}
                                </div>
                            </div>
                            <div className="code-line" />
                            <div className="mobile">
                                <div className="bottom-legend">{medicText}</div>

                                <div className="doqchat-logo-container">
                                    <img
                                        src="https://doqchat.com/static/doqchat-logo.png"
                                        className="doqchat-logo"
                                        alt="doq-chat logo"
                                    ></img>
                                </div>

                                <div className="bottom-container">
                                    <AsyncButton
                                        className="button"
                                        onClick={() => {
                                            firebase.analytics().logEvent("webcard_open_app_clicked");
                                            ReactPixel.trackCustom("webcard_open_app_clicked");
                                            window.open("https://t.me/doqchatbot");
                                            // window.open(this.state.professional.urlButton, "_self");
                                        }}
                                    >
                                        Ir a DoqChat
                                    </AsyncButton>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <div className="error-page-container">
                    <div
                        style={{
                            paddingTop: 50
                        }}
                    >
                        <Loader />
                        {this.state.redirectLoading && (
                            <div
                                style={{
                                    marginTop: 14
                                }}
                            >
                                Redireccionando...
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div className="body">
                <div className="page">
                    <section className="section">
                        <div className="header">
                            {this.state.professional.photoId ? (
                                <div className="profile-image-wrapper">
                                    <img
                                        className="profile-image"
                                        alt="doqchat profile"
                                        src={`${process.env.REACT_APP_BASE_API_URL}/v1/files/${encodeURIComponent(
                                            this.state.professional.photoId
                                        )}`}
                                        onLoad={() => {
                                            this.setState({
                                                profileImageLoaded: true
                                            });
                                        }}
                                    />

                                    {!this.state.profileImageLoaded && (
                                        <div className="profile-image-loader">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="loader"
                                                width="100px"
                                                height="100px"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="xMidYMid"
                                            >
                                                <path
                                                    d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
                                                    fill="#e15b64"
                                                    stroke="none"
                                                    transform="rotate(179.896 50 51)"
                                                >
                                                    <animateTransform
                                                        attributeName="transform"
                                                        type="rotate"
                                                        dur="1s"
                                                        repeatCount="indefinite"
                                                        keyTimes="0;1"
                                                        values="0 50 51;360 50 51"
                                                    ></animateTransform>
                                                </path>
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="profile-image-wrapper">
                                    <img className="profile-image" src={profile} />
                                </div>
                            )}

                            <div>
                                {true && (
                                    <div className="name">{`${getTitle(this.state.professional.title)} ${
                                        this.state.professional.firstName
                                    } ${this.state.professional.lastName}`}</div>
                                )}

                                {this.state.professional.tipo === 2 && (
                                    <div className="name">{`${this.state.professional.firstName}`}</div>
                                )}

                                {true && this.state.professional.speciality && (
                                    <div className="speciality">{this.state.professional.speciality}</div>
                                )}

                                {this.state.professional.tipo === 2 && <div className="speciality">Institución</div>}
                            </div>
                        </div>

                        {this.state.professional && this.state.professional.bio ? (
                            <div className="about-me-container">
                                <div className="about-me-top">
                                    <div className="about-me-title">Sobre mi</div>

                                    <div className="code-line"></div>

                                    {this.state.professional &&
                                    (this.state.professional.facebook ||
                                        this.state.professional.instagram ||
                                        this.state.professional.webPersonal) ? (
                                        <div className="social-links-container">
                                            {this.state.professional && this.state.professional.facebook ? (
                                                <SocialNetworkItem
                                                    link={this.state.professional.facebook}
                                                    backgroundClass="facebook-background"
                                                    icon="fa fa-facebook"
                                                />
                                            ) : null}

                                            {this.state.professional && this.state.professional.instagram ? (
                                                <SocialNetworkItem
                                                    link={`https://instagram.com/${this.state.professional.instagram}`}
                                                    backgroundClass="instagram-background"
                                                    icon="fa fa-instagram"
                                                />
                                            ) : null}

                                            {this.state.professional && this.state.professional.webPersonal ? (
                                                <SocialNetworkItem
                                                    link={this.state.professional.webPersonal}
                                                    backgroundClass="web-background"
                                                    icon="fa fa-globe"
                                                />
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>

                                {this.state.professional && this.state.professional.bio ? (
                                    <div className="bio-text">
                                        <ReadMoreAndLess
                                            className="read-more-content"
                                            charLimit={200}
                                            readMoreText=" Ver más"
                                            readLessText=" Ver menos"
                                        >
                                            {this.state.professional.bio}
                                        </ReadMoreAndLess>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        <div
                            className={
                                "code" +
                                ((this.state.professional && !this.state.professional.bio) ||
                                (this.state.professional &&
                                    !this.state.professional.facebook &&
                                    !this.state.professional.instagram &&
                                    !this.state.professional.web)
                                    ? " row"
                                    : " row-reverse")
                            }
                        >
                            <div className="code-line" />
                            <div
                                className={
                                    "code-value" +
                                    ((this.state.professional && !this.state.professional.bio) ||
                                    (this.state.professional &&
                                        !this.state.professional.facebook &&
                                        !this.state.professional.instagram &&
                                        !this.state.professional.web)
                                        ? " ml-10"
                                        : " mr-10")
                                }
                            >
                                {`CODIGO: ${this.state.professional.code}`}
                            </div>
                        </div>

                        <div className="mobile">
                            <div className="bottom-legend">{medicText}</div>

                            <div className="doqchat-logo-container">
                                <img
                                    src="https://doqchat.com/static/doqchat-logo.png"
                                    className="doqchat-logo"
                                    alt="doq-chat logo"
                                ></img>
                            </div>

                            {!this.state.professional.deleted && (
                                <>
                                    <div
                                        className="bottom-container"
                                        style={{ display: "flex", flexDirection: "column", gap: 8 }}
                                    >
                                        <AsyncButton className="button" onClick={this.handleCTA}>
                                            {this.state.downloadLoading &&
                                            this.state.professional.saveContactMainFlow ? (
                                                <ButtonLoader variant="secondary" size="small" />
                                            ) : this.state.professional.saveContactMainFlow ? (
                                                "Guardar contacto"
                                            ) : (
                                                "Iniciar Consulta"
                                            )}
                                        </AsyncButton>

                                        {/* Guardar contacto: loading con vcf */}

                                        <AsyncButton
                                            className="button-secondary"
                                            onClick={() => {
                                                if (this.state.professional.saveContactMainFlow) {
                                                    this.openPopup();
                                                } else {
                                                    this.getVCFCard(this.state.professional.id);
                                                }
                                            }}
                                        >
                                            {this.state.downloadLoading &&
                                            !this.state.professional.saveContactMainFlow ? (
                                                <ButtonLoader variant="primary" size="small" />
                                            ) : this.state.professional.saveContactMainFlow ? (
                                                "Iniciar Consulta"
                                            ) : (
                                                "Guardar contacto"
                                            )}
                                        </AsyncButton>
                                    </div>
                                </>
                            )}
                        </div>

                        {this.state.professional &&
                        !this.state.professional.bio &&
                        (this.state.professional.facebook ||
                            this.state.professional.instagram ||
                            this.state.professional.webPersonal) ? (
                            <div className="social-links-container-bottom">
                                {this.state.professional && this.state.professional.facebook ? (
                                    <SocialNetworkItem
                                        link={this.state.professional.facebook}
                                        backgroundClass="facebook-background"
                                        icon="fa fa-facebook"
                                    />
                                ) : null}

                                {this.state.professional && this.state.professional.instagram ? (
                                    <SocialNetworkItem
                                        link={`https://instagram.com/${this.state.professional.instagram}`}
                                        backgroundClass="instagram-background"
                                        icon="fa fa-instagram"
                                    />
                                ) : null}

                                {this.state.professional && this.state.professional.webPersonal ? (
                                    <SocialNetworkItem
                                        link={this.state.professional.webPersonal}
                                        backgroundClass="web-background"
                                        icon="fa fa-globe"
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </section>
                </div>
                <Popup
                    isOpen={this.state.isPopupOpen}
                    onClose={this.closePopup}
                    professional={this.state.professional}
                />
            </div>
        );
    }
}

export default App;

const SocialNetworkItem = (props) => {
    return (
        <a className="social-link" href={props.link} target="_blank">
            <div className={props.backgroundClass}>
                <i
                    style={{
                        width: 15,
                        height: 14,
                        fontSize: 16,
                        color: "#fff"
                    }}
                    class={props.icon}
                    aria-hidden="true"
                />
            </div>
        </a>
    );
};
