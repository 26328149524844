export function getRedirectId(url) {
    try {
        // Crear objeto URL para manipular fácilmente la URL
        const urlObj = new URL(url);

        // Obtener la ruta (path)
        const path = urlObj.pathname;

        // Verificar si contiene "/s/"
        if (path.includes("/s/")) {
            // Extraer el ID después de "/s/"
            const parts = path.split("/s/");
            if (parts.length > 1) {
                return parts[1]; // Devuelve el ID (123456 en el ejemplo)
            }
        }

        // Si no es una URL de redirección o no se encuentra el ID
        return null;
    } catch (error) {
        console.error("Error al procesar la URL:", error);
        return null;
    }
}

/**
 * Función para cerrar la webview o redirigir al usuario dependiendo de la plataforma
 * Soporta: navegador web, WhatsApp, Telegram y apps nativas
 */
export const closeWebCardApp = () => {
    // Detectar el entorno/plataforma
    const userAgent = navigator.userAgent || "";
    const isWhatsApp = userAgent.indexOf("WhatsApp") !== -1;
    const isTelegram = userAgent.indexOf("Telegram") !== -1 || window.Telegram;

    // Intentar detectar si estamos en una WebView de una app nativa
    const isNativeApp =
        userAgent.indexOf("wv") !== -1 || // Android WebView
        (/iPhone|iPad|iPod/.test(userAgent) && !userAgent.includes("Safari")); // iOS WebView

    try {
        // 1. Manejo específico para WhatsApp
        if (isWhatsApp) {
            // Intentar usar API de WhatsApp si está disponible
            if (window.Android && window.Android.close) {
                window.Android.close();
                return;
            }
            // Alternativa: redirigir a la pantalla principal de WhatsApp
            window.location.href = "whatsapp://";
            return;
        }

        // 2. Manejo específico para Telegram
        if (isTelegram) {
            // Usar la API de Telegram Web si está disponible
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.close();
                return;
            }
            // Alternativa: redirigir a Telegram
            window.location.href = "tg://";
            return;
        }

        // 3. Manejo para apps nativas (Android/iOS)
        if (isNativeApp) {
            // Intentar usar puentes nativos comunes
            if (window.ReactNativeWebView) {
                // Para WebView de React Native
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "CLOSE_WEBVIEW" }));
                return;
            }

            if (window.webkit && window.webkit.messageHandlers) {
                // Para WebView en iOS
                window.webkit.messageHandlers.close.postMessage("close");
                return;
            }

            if (window.Android) {
                // Para WebView en Android
                window.Android.closeWebView();
                return;
            }

            // Último recurso para WebViews: historia vacía y window.close()
            window.history.replaceState(null, "", "about:blank");
            window.close();
            return;
        }

        // 4. Navegador web estándar
        // Primera opción: cerrar la ventana (funciona si fue abierta con window.open)
        window.close();

        // Si window.close() no funcionó (comprobamos después de un pequeño delay)
        setTimeout(() => {
            if (!window.closed) {
                // Alternativa: redirigir a una URL específica de tu app
                // window.location.href = 'https://tuapp.com/home';

                // O mostrar un mensaje al usuario
                const message = document.createElement("div");
                message.style.cssText =
                    "position:fixed;top:0;left:0;right:0;background:#e95855;color:white;padding:15px;text-align:center;z-index:9999;";
                message.innerHTML = "Por favor, cierre esta pestaña para volver a la aplicación.";
                document.body.appendChild(message);
            }
        }, 300);
    } catch (error) {
        console.error("Error al intentar cerrar la webview:", error);
        // Último intento: window.close() estándar
        window.close();
    }
};
